export default {
    methods: {
        async uploadFile(file) {
            await this.$recaptchaLoaded()
            let recaptchaToken = await this.$recaptcha('login')

            return new Promise((resolve, reject) => {
                if(file.size > 3000000) {
                    reject({
                        response: {
                            status: 413,
                            data: {
                                errors: {
                                    file: this.$lang.app.file_size_limit_validation
                                }
                            }
                        }
                    })
                    return
                }

                let formData = new FormData();
                formData.append('file', file);
                formData.append('recaptchaToken', recaptchaToken)

                this.$axios.post( '/file/uploadFile',
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(({data}) => {
                        resolve(data.fileName)
                    })
                    .catch(error => {
                        reject(error)
                    });
            })
        },
        downloadFile(fileName) {
            return new Promise((resolve, reject) => {
                this.$axios.get( `/file/downloadFile/${fileName}`, { responseType: "blob" })
                    .then(({data}) => {
                        resolve(data)
                    })
                    .catch(error => {
                        this.$snotify.error(error)

                        reject(error)
                    });
            })
        }
    }
}