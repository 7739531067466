<template>
  <div class="admin-detail">
    <div class="admin-navbar justify-between">
      <button class="action-button" @click="$router.go(-1)">{{ $lang.app.back }}</button>
      <div class="detail-buttons">
        <button class="detail-button"
                @click="$router.push(`/admin/law/${$route.params.id}/edit`)">
          {{ $lang.app.edit }}</button>
      </div>
    </div>
    <div v-if="lawItem" class="admin-info">
      <h4 class="info-title">{{ $lang.app.law }}</h4>
      <div class="info-fields">
        <div class="info-field">
          <p class="field-label">{{ $lang.app.heading }} ({{ $lang.app.ru }}):</p>
          <p class="field-value">{{ lawItem.titleRu }}</p>
        </div>
        <div class="info-field">
          <p class="field-label">{{ $lang.app.heading }} ({{ $lang.app.kg }}):</p>
          <p class="field-value">{{ lawItem.titleKg }}</p>
        </div>
        <div class="info-field">
          <p class="field-label">{{ $lang.app.file }}:</p>
          <p class="field-value">
            <a href @click.prevent="openFile">
              {{ lawItem.filename }}
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import fileUploadMixin from "../../../mixins/fileUploadMixin";

export default {
  mixins: [fileUploadMixin],
  data() {
    return {
      lawItem: null,
    }
  },
  methods: {
    fetchResource() {
      this.$axios.get(`/legal-act/${this.$route.params.id}`).then(({data}) => {
        this.lawItem = data
      })
    },
    openFile() {
      this.downloadFile(this.lawItem.filename).then(data => {
        let extension = this.lawItem.filename.split('.').pop().toLowerCase();
        if(['png', 'jpg', 'jpeg'].includes(extension)) {
          let file = URL.createObjectURL(new Blob([data],{type: `image/${extension}`}));
          window.open(file, '_blank');
        }
        if(extension === 'pdf') {
          let file = URL.createObjectURL(new Blob([data],{type: 'application/pdf'}));
          window.open(file, '_blank');
        }
      })
    },
  },
  mounted() {
    this.fetchResource()
  }
}
</script>
<style>
@import '../../../assets/styles/admin/detail.css';
@import '../../../assets/styles/admin/info.css';
@import '../../../assets/styles/admin/navbar.css';
</style>